import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—January 2006" />
	<Old2005BlogPage>
		<h2>January 2006</h2>



		<h3 className="blogdate">
		Thursday, January 04, 2007
		</h3>






		<h4 className="blogitemtitle">
		Taking a break
		</h4>





			<div className="blogitembody">
			I've been slack; I don't have much to say on my blog.  You'll have to excuse me - after 2.5 months of freezing my ass of in Wellington, New Zealand, I am now getting sucked into surf-time in Burleigh Heads (Gold Coast, Queensland), Australia.<br /><br />Surf-time includes frolicking in the warm surf, eating ripe mangoes, papaya, passionfruit, and lychees, rehearsing music on the beach, walking through a nearly-rain-forest, chilling with Merz, Chopper, B-lair, Azza, Jezza, Lisa, etc., and moderately drinking Coopers Pale Ale.<br /><br />Oh, how I wish I could work and live here.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:59 PM &nbsp;
			</div>




		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
